import React, { Component, PropTypes } from 'react'
import { ActionCreators } from 'redux-undo';
import keypress from 'keypress';


export const listener = new keypress.Listener();


export default class KeyboardShortcuts extends Component {

    static propTypes = {
        dispatch: PropTypes.func,
        polyshaper: PropTypes.func,
        shapeFill: PropTypes.bool,
        shapeStroke: PropTypes.bool,
        imageVisibility: PropTypes.bool
    };

    componentDidMount() {
        let { dispatch } = this.props;
        let polyshaper = this.props.polyshaper;
        let stage = polyshaper.stage();
    }

    shouldComponentUpdate() { return false; }

    render() { return null; }
}