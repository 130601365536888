import React, { Component, PropTypes } from 'react'
import shouldPureComponentUpdate from 'react-pure-render/function';
import Droparea from 'react-droparea';
import DebounceInput from 'react-debounce-input';


import { setShapeStroke, setShapeStrokeColorMode, setShapeStrokeColor,
         setShapeStrokeOpacity, setShapeFill, setShapeFillColorMode,
         setShapeFillColor, setShapeFillOpacity, setImage, importImage,
         setImageVisibility, setImageOpacity, setColorManipulation,
         setColorManipulationInFill, setColorManipulationInStroke,
         setShapingAlgorithm, resetColorManipulation, clearPoints
        } from '../actions/polyshaper';

import MouseToolsControls from './MouseTools';
import PointExtractorsControls from './PointExtractors';


class Toggler extends Component {

    static propTypes = {
        display: PropTypes.bool.isRequired,
        children: PropTypes.arrayOf(PropTypes.element).isRequired
    };

    shouldComponentUpdate = shouldPureComponentUpdate;

    render() {
        return (
            <div>{this.props.display ? this.props.children : []}</div>
        );
    }
}


class Controls extends Component {

    static propTypes = {
        polyshaper: PropTypes.func.isRequired,
        points: PropTypes.oneOfType([
            PropTypes.array,
            PropTypes.arrayOf(PropTypes.array)
        ]),
        dispatch: PropTypes.func,
        shapeStroke: PropTypes.bool,
        shapeStrokeColorMode: PropTypes.string,
        shapeStrokeColor: PropTypes.string,
        shapeStrokeOpacity: PropTypes.number,
        shapeFill: PropTypes.bool,
        shapeFillOpacity: PropTypes.number,
        shapeFillColorMode: PropTypes.string,
        shapeFillColor: PropTypes.string,
        imageOpacity: PropTypes.number,
        image: PropTypes.object,
        imageData: PropTypes.object,
        imageVisibility: PropTypes.bool,
        tool: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string
        ]),
        pointExtractor: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string
        ]),
        colorManipulationInStroke: PropTypes.bool.isRequired,
        colorManipulationInFill: PropTypes.bool.isRequired,
        colorManipulation: PropTypes.object.isRequired
    };

    shouldComponentUpdate = shouldPureComponentUpdate;

    handleClearPoints() {
        this.props.dispatch(clearPoints());
    }

    handleResetColorManipulation() {
        this.props.dispatch(resetColorManipulation());
    }

    handleDownloadSVG(e) {
        this.props.polyshaper.exportSVG(e.target);
    }

    handleDownloadJPG(e) {
        this.props.polyshaper.exportJPG(e.target);
    }

    render() {
        let props = this.props;
        let {dispatch} = props;

        return (<div className="Controls">

            <div className="Controls-header">
                <MouseToolsControls
                    active={props.tool}
                    dispatch={dispatch}
                    polyshaper={props.polyshaper}
                    points={props.points}
                    shapes={props.shapes}
                />
            </div>

            <div className="Controls-body">
                <h2 className="Group-label">
                    {`Points (${this.props.points.length})`}
                    <div className="Group-actions">
                        <a onClick={this.handleClearPoints.bind(this)}>
                            {'Clear points'}
                        </a>
                    </div>
                </h2>

                <PointExtractorsControls
                    selected={props.pointExtractor}
                    dispatch={dispatch}
                    polyshaper={props.polyshaper}
                    image={props.image}
                    imageData={props.imageData}
                />

                {false && <h2 className="Group-label">
                    {'Shaping algorithm'}
                    <div className="Group-actions">
                        <select value={props.shapingAlgorithm}
                            onChange={e => dispatch(setShapingAlgorithm(e.target.value))}
                        >
                            {['delaunay', 'voronoi'].map((shaping) => {
                                return (
                                    <option
                                        value={shaping}
                                        key={shaping}
                                    >
                                        {shaping}
                                    </option>);
                            })}
                        </select>
                    </div>
                </h2>}



                <h2 className="Group-label">
                    {'Image'}
                    <Droparea
                        onDrop={(files) => dispatch(importImage(files))}
                        className={'Group-actions'}
                        multiple={false}
                        accept={'image/*'}
                    >
                        <a>{'Click to import'}</a>
                        <p>{', or drag one'}</p>
                    </Droparea>
                </h2>

                <div className="Field">
                    <label>{'Image toggle'}</label>
                    <input type="checkbox"
                        checked={props.imageVisibility}
                        onChange={e => dispatch(
                            setImageVisibility(e.target.checked))}
                    />
                </div>

                <div className="Field">
                    <label>{`Image Opacity (${props.imageOpacity})`}</label>
                    <DebounceInput type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={props.imageOpacity.toString()}
                        debounceTimeout={160}
                        onChange={e => dispatch(setImageOpacity(e.target.value))}
                    />
                </div>


                <h2 className="Group-label">{'Shapes'}</h2>

                <div className="Field">
                    <label>{'Fill toggle'}</label>
                    <input type="checkbox"
                        checked={props.shapeFill}
                        onChange={e => dispatch(setShapeFill(e.target.checked))}
                    />
                </div>
                <Toggler display={props.shapeFill}>
                    <div className="Field">
                        <label>{`Fill opacity (${props.shapeFillOpacity})`}</label>
                        <DebounceInput type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={props.shapeFillOpacity.toString()}
                            debounceTimeout={160}
                            onChange={e => dispatch(setShapeFillOpacity(e.target.value))}
                        />
                    </div>
                    <div className="Field Field--choices">
                        <label>{'Fill Color'}</label>
                        <ul>
                            <li>
                                <input name="fillcolormode[]"
                                    id="fill-centroid"
                                    type="radio"
                                    checked={props.shapeFillColorMode === 'centroid'}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            dispatch(setShapeFillColorMode('centroid'))
                                        }
                                    }}
                                />
                                <label htmlFor="fill-centroid">{'Centroid'}</label>
                            </li>
                            <li>
                                <input name="fillcolormode[]"
                                    id="fill-average"
                                    type="radio"
                                    checked={props.shapeFillColorMode === 'average'}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            dispatch(setShapeFillColorMode('average'))
                                        }
                                    }}
                                />
                                <label htmlFor="fill-average">{'Average'}</label>
                            </li>
                            <li>
                                <input name="fillcolormode[]"
                                    type="radio"
                                    checked={props.shapeFillColorMode === 'custom'}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            dispatch(setShapeFillColorMode('custom'))
                                        }
                                    }}
                                />
                                <DebounceInput type="color"
                                    value={props.shapeFillColor}
                                    debounceTimeout={160}
                                    onChange={e => dispatch(setShapeFillColor(e.target.value))}
                                />
                            </li>
                        </ul>
                    </div>
                </Toggler>

                <div className="Field">
                    <label>{'Stroke toggle'}</label>
                    <input type="checkbox"
                        checked={props.shapeStroke}
                        onChange={e => dispatch(setShapeStroke(e.target.checked))}
                    />
                </div>
                <Toggler display={props.shapeStroke}>
                    <div className="Field">
                        <label>{`Stroke opacity (${props.shapeStrokeOpacity})`}</label>
                        <DebounceInput type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={props.shapeStrokeOpacity.toString()}
                            debounceTimeout={160}
                            onChange={e => dispatch(setShapeStrokeOpacity(e.target.value))}
                        />
                    </div>
                    <div className="Field Field--choices">
                        <label>{'Stroke Color'}</label>
                        <ul>
                            <li>
                                <input name="strokecolormode[]"
                                    id="stroke-centroid"
                                    type="radio"
                                    checked={props.shapeStrokeColorMode === 'centroid'}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            dispatch(setShapeStrokeColorMode('centroid'))
                                        }
                                    }}
                                />
                                <label htmlFor="stroke-centroid">{'Centroid'}</label>
                            </li>
                            <li>
                                <input name="strokecolormode[]"
                                    id="stroke-average"
                                    type="radio"
                                    checked={props.shapeStrokeColorMode === 'average'}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            dispatch(setShapeStrokeColorMode('average'))
                                        }
                                    }}
                                />
                                <label htmlFor="stroke-average">{'Average'}</label>
                            </li>
                            <li>
                                <input name="strokecolormode[]"
                                    type="radio"
                                    checked={props.shapeStrokeColorMode === 'custom'}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            dispatch(setShapeStrokeColorMode('custom'))
                                        }
                                    }}
                                />
                                <DebounceInput type="color"
                                    value={props.shapeStrokeColor}
                                    debounceTimeout={160}
                                    onChange={e => dispatch(setShapeStrokeColor(e.target.value))}
                                />
                            </li>
                        </ul>
                    </div>
                </Toggler>


                <h2 className="Group-label">
                    {'Color Manipulation'}
                    <div className="Group-actions">
                        <a onClick={this.handleResetColorManipulation.bind(this)}>
                            {'Reset'}
                        </a>
                    </div>
                </h2>

                <div className="Field">
                    <label>{'Affects Stroke'}</label>
                    <input type="checkbox"
                        checked={props.colorManipulationInStroke}
                        onChange={e => dispatch(
                            setColorManipulationInStroke(e.target.checked))}
                    />
                </div>
                <div className="Field">
                    <label>{'Affects Fill'}</label>
                    <input type="checkbox"
                        checked={props.colorManipulationInFill}
                        onChange={e => dispatch(
                            setColorManipulationInFill(e.target.checked))}
                    />
                </div>

                <div className="Field">
                    <label>{`Rotate (${props.colorManipulation.spin})`}</label>
                    <DebounceInput type="range"
                        min="1"
                        max="360"
                        step="1"
                        value={props.colorManipulation.spin.toString()}
                        debounceTimeout={160}
                        onChange={e => dispatch(setColorManipulation('spin', e.target.value))}
                    />
                </div>
                <div className="Field">
                    <label>{`Saturate (${props.colorManipulation.saturate})`}</label>
                    <DebounceInput type="range"
                        min="0"
                        max="100"
                        step="1"
                        value={props.colorManipulation.saturate.toString()}
                        debounceTimeout={160}
                        onChange={e => dispatch(setColorManipulation('saturate', e.target.value))}
                    />
                </div>
                <div className="Field">
                    <label>{`Desaturate (${props.colorManipulation.desaturate})`}</label>
                    <DebounceInput type="range"
                        min="0"
                        max="100"
                        step="1"
                        value={props.colorManipulation.desaturate.toString()}
                        debounceTimeout={160}
                        onChange={e => dispatch(setColorManipulation('desaturate', e.target.value))}
                    />
                </div>
                <div className="Field">
                    <label>{`Brighten (${props.colorManipulation.brighten})`}</label>
                    <DebounceInput type="range"
                        min="0"
                        max="100"
                        step="1"
                        value={props.colorManipulation.brighten.toString()}
                        debounceTimeout={160}
                        onChange={e => dispatch(setColorManipulation('brighten', e.target.value))}
                    />
                </div>
                <div className="Field">
                    <label>{`Lighten (${props.colorManipulation.lighten})`}</label>
                    <DebounceInput type="range"
                        min="0"
                        max="100"
                        step="1"
                        value={props.colorManipulation.lighten.toString()}
                        debounceTimeout={160}
                        onChange={e => dispatch(setColorManipulation('lighten', e.target.value))}
                    />
                </div>
                <div className="Field">
                    <label>{`Darken (${props.colorManipulation.darken})`}</label>
                    <DebounceInput type="range"
                        min="0"
                        max="100"
                        step="1"
                        value={props.colorManipulation.darken.toString()}
                        debounceTimeout={160}
                        onChange={e => dispatch(setColorManipulation('darken', e.target.value))}
                    />
                </div>
            </div>


            <div className="Controls-footer">
                <h2 className="Group-label">{'Export'}</h2>

                <div className="Field">
                    <a className="Button Button--native"
                        onClick={this.handleDownloadSVG.bind(this)}
                    >{'Download SVG'}</a>
                    <a className="Button Button--native"
                        onClick={this.handleDownloadJPG.bind(this)}
                    >{'Download JPG'}</a>
                </div>
            </div>
        </div>);
    }
}

// export default Controls
export default Controls;