import React, { Component, PropTypes } from 'react'
import shouldPureComponentUpdate from 'react-pure-render/function';
import Modal from 'react-modal';
import Tooltip from 'rc-tooltip';

import { showModal, hideModal } from '../actions/app';

import { listener } from '../components/KeyboardShortcuts';

export const MODALS = [{
    id: 'homepage',
    label: 'Homepage',
    externalUrl: 'http://polyshaper.co/',
}]

const customModalStyles = {
    content : {
        background: 'none',
        border: 'none',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        padding: 0,
        borderRadius: 0
    }
};

export default class Modals extends Component {

    static propTypes = {
        activeModal: React.PropTypes.string.isRequired,
        dispatch: PropTypes.func.isRequired
    };

    shouldComponentUpdate = shouldPureComponentUpdate;

    render() {
        // stop listening for keyboard shortcuts when modals are open
        if (this.props.activeModal) {
            listener.stop_listening();
        } else {
            listener.listen();
        }

        let {activeModal, dispatch} = this.props;

        return (
            <div>
                {MODALS.map((modal) => {
                    let Component = modal.component;
                    return (
                        <Modal key={modal.id}
                            closeTimeoutMS={150}
                            isOpen={activeModal === modal.id}
                            style={customModalStyles}
                            onRequestClose={() => dispatch(hideModal(modal.id))}
                        >
                            <div className="Modal">
                                <span className="Modal-close"
                                    onClick={() => dispatch(hideModal(modal.id))}
                                >
                                    {'✖'}
                                </span>
                                {modal.label && <h1 className="Modal-title">{modal.label}</h1>}
                                {Component && <Component {...this.props} />}
                            </div>
                        </Modal>);
                })}
            </div>);
    }
}


export class ModalButtons extends Component {

    static propTypes = {
        dispatch: PropTypes.func.isRequired
    };

    render() {
        let {dispatch} = this.props;

        let tipContent = (
            <menu className={'Links'}>
                {MODALS.filter(modal => modal.button !== false)
                    .map((modal) => {
                        let props = {};
                        if (modal.externalUrl) {
                            props.href = modal.externalUrl;
                            props.target = '_blank';
                        } else {
                            props.onClick = () => dispatch(showModal(modal.id));
                        }

                        return (
                            <a key={modal.id}
                                {...props}
                            >
                                {modal.label}
                            </a>);
                })}
            </menu>);

        return (
            <Tooltip placement="topLeft"
                trigger={['click']}
                overlay={tipContent}
            >
                <button className="HelpButton">{'?'}</button>
            </Tooltip>);
    }
}