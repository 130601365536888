import React, { Component, PropTypes } from 'react';
import createjs from 'createjs';

import pointInCircle from 'point-circle-collision';
import debounce from 'lodash.debounce';
import MouseTool from './base';
import { removePoints } from '../../actions/polyshaper';
import { batchActions } from 'redux-batched-actions';



export default class BrushMouseTool extends MouseTool {
    constructor(props) {
        super(props);
        this.state = {
            size: 40
        }
    }

    render() {
        if (!this.isActive()) {
            return null;
        } else {
            return (
                <div className="Field">
                    <label>{`Eraser Size (${this.state.size})`}</label>
                    <input type="range"
                        min="1"
                        max="200"
                        step="1"
                        defaultValue={this.state.size}
                        onChange={(e) => {
                            this.setState({
                                size: e.target.value
                            })
                        }}
                    />
                </div>
            );
        }

    }
}

BrushMouseTool.defaultProps = {
    name: 'eraser',
    order: 3,
    keys: 'e',
    mouseEvent: 'stagemousedown',
    task: function(e) {
        let polyshaper = this.props.polyshaper;
        let stage = polyshaper.stage();
        let bitmap = polyshaper.bitmap();
        let radius = this.state.size / 2;
        let toolsLayer = polyshaper.toolsLayer();
        let local = stage.globalToLocal(e.stageX, e.stageY);

        let circle = new createjs.Shape();
        circle.graphics.setStrokeStyle(1, 'butt', 'miter', 10, true)
            .beginStroke('black').drawCircle(0, 0, radius / stage.scaleX);
        circle.x = local.x;
        circle.y = local.y;
        toolsLayer.addChild(circle);
        stage.update();

        stage.addEventListener('stagemousemove', (e) => {
            let local = stage.globalToLocal(e.stageX, e.stageY);
            let points = this.props.points;

            circle.x = local.x;
            circle.y = local.y;
            circle.radius = radius / stage.scaleX;

            let toDeletePoints = points.filter((p) => {
                return pointInCircle(
                    polyshaper.pointToArtboard(p),
                    [local.x, local.y],
                    radius / stage.scaleX);
            });

            let pointsIdxs = toDeletePoints.map((p) => {
                return points.indexOf(p);
            });

            if (pointsIdxs.length) {
                this.props.dispatch(removePoints(pointsIdxs));
            }

            stage.update();
        });

        stage.addEventListener('stagemouseup', function() {
            toolsLayer.removeAllChildren();
            stage.update();
            stage.removeAllEventListeners('stagemousemove');
        });
    }
}