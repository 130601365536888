// this is taken from danscan/rn-redux-mixpanel, but I didn't want to
// add another dependency for ajax (superagent)

import base64 from 'base-64';
import utf8 from 'utf8';
import store from '../store';
import 'whatwg-fetch';

// Mixpanel Service Constants
const MIXPANEL_REQUEST_PROTOCOL = 'https'
const MIXPANEL_HOST = 'api.mixpanel.com'

const TRACKED_ACTIONS = [
    'ADD_POINTS',
    'CLEAR_POINTS',
    'SET_POINT_EXTRACTION',
    'LICENSE_VERIFIED',
    'SHOW_MODAL',
    'SET_SHAPE_STROKE',
    'SET_SHAPE_STROKE_OPACITY',
    'SET_SHAPE_STROKE_COLOR_MODE',
    'SET_SHAPE_STROKE_COLOR',
    'SET_SHAPE_FILL',
    'SET_SHAPE_FILL_OPACITY',
    'SET_SHAPE_FILL_COLOR_MODE',
    'SET_SHAPE_FILL_COLOR',
    'IMPORT_IMAGE',
    'EXPORT_IMAGE',
    'SET_IMAGE_VISIBILITY',
    'SET_IMAGE_OPACITY',
    'SET_COLOR_MANIPULATION',
    'SET_MOUSE_TOOL'
];

// by default exclude all
const EXCLUDE_PROPS_ACTIONS = {
    'ADD_POINTS': [],
    'IMPORT_IMAGE': [],
    'EXPORT_IMAGE': ['mimetype']
}


function toBase64(string) {
    var bytes = utf8.encode(string);
    return base64.encode(bytes);
}

function mixpanelDate() {
    // see https://mixpanel.com/help/reference/http#dates-in-updates
    return new Date().toISOString().slice(0, 19);
}


export function sendMixpanelRequest(mixpanelEndpoint, data) {
    const requestDataString = JSON.stringify(data)
    const requestDataBase64String = toBase64(requestDataString)
    const endpoint = `${MIXPANEL_REQUEST_PROTOCOL}://${MIXPANEL_HOST}${mixpanelEndpoint}`
    const qs = `?ip=1&data=${requestDataBase64String}`;
    const endpointURL = `${endpoint}${qs}`;

    return fetch(endpointURL).then((response) => {
        return response.json();
    });
}

export let trackEvent = ({ eventName, eventData = {} }) => {
    let state = store.getState();
    // Build event properties
    const eventProperties = {
        ...eventData, // first one, to ensure token and distinct_id are not overriden
        token: process.env.MIXPANEL_TOKEN
    }

    // Build request data for event track request
    const trackRequestData = {
        event: eventName,
        properties: eventProperties
    }

    return sendMixpanelRequest('/track', trackRequestData);
}

export let identify = () => {
    let state = store.getState();
    // Build request data for event track request

    const engageRequestData = {
        $token: process.env.MIXPANEL_TOKEN
    }

    return sendMixpanelRequest('/engage', engageRequestData);
}



export let mixpanelMiddleware = ({
    selectDistinctId = (action, state) => {
        return distinctIdAccessor(state);
    },
    ignoreAction = (action) => {
        if (TRACKED_ACTIONS.indexOf(action.type) > -1) {
            return false;
        }
        return true;
    },
    selectEventName = (action) => action.type,
    selectProperties = (action, state) => {
        let payload;

        let actionWithExcludedPropsIdx = Object.keys(EXCLUDE_PROPS_ACTIONS)
            .indexOf(action.type);

        if (EXCLUDE_PROPS_ACTIONS[action.type]) {
            let propsTopick = EXCLUDE_PROPS_ACTIONS[action.type] || [];
            return propsTopick.reduce((result, propKey) => {
                if (typeof action.payload[propKey] !== undefined) {
                    result[propKey] = action.payload[propKey];
                }
                return result;
            }, {});
        }

        if (typeof action.payload === 'string' || typeof action.payload === 'number') {
            payload = { value: action.payload };
        } else if (typeof action.payload === 'array') {
            payload = { value: action.payload.join(', ') };
        } else {
            payload = action.payload;
        }

        return payload;
    }
}) => store => next => action => {
    // Don't track falsy actions or actions that should be ignored
    if (!action.type || ignoreAction(action)) {
        return next(action);
    }

    // Get store state; select distinct id for action & state
    const state = store.getState();
    const eventName = selectEventName(action, state);
    const properties = selectProperties(action, state);

    // Track action event with Mixpanel
    trackEvent({
        eventName: eventName,
        eventData: properties
    });

    return next(action)
}
