import isPointInside from 'point-in-polygon';
import tinycolor from 'tinycolor2';
import { resizeCanvas } from 'pica';

const IMAGE_MAX_WIDTH = 800;
const IMAGE_MAX_HEIGHT = 800;


export function imageToCanvas(img) {
    // Create an empty canvas element
    let canvas = document.createElement('canvas');

    canvas.width = img.width;
    canvas.height = img.height;

    // Copy the image contents to the canvas
    let context = canvas.getContext('2d');
    context.save();
    // context.scale(1, -1);
    context.drawImage(img, 0, 0);
    context.restore();

    return {canvas, context};
}

export function getImageData(img) {
    let {canvas, context} = imageToCanvas(img);
    return context.getImageData(0, 0, canvas.width, canvas.height);
}

export function resizeImage(img) {
    let {canvas} = imageToCanvas(img);
    let destCanvas = document.createElement('canvas');
    let ratio = Math.min(IMAGE_MAX_WIDTH / img.width, IMAGE_MAX_HEIGHT / img.height);
    let toWidth = img.width * ratio;
    let toHeight = img.height * ratio;

    destCanvas.width = toWidth;
    destCanvas.height = toHeight;

    return new Promise(function(resolve, reject) {
        resizeCanvas(canvas, destCanvas, {}, (err, output) => {
            if (err) { reject(err); }
            else {
                let image = new Image();
                image.crossOrigin = 'Anonymous';
                image.addEventListener('load', function() {
                    resolve(image);
                });
                image.src = destCanvas.toDataURL();
            }
        });
    });
}


export function loadImage(image) {
    let p = typeof image === 'string' ?
        loadImageURL(image) :
        loadDraggedImage(image);

    return p.then((image) => {
        return { image: image};
    }).catch((error) => {
        return { error: error };
    });
}


export function loadImageURL(url) {
    return new Promise(function(resolve, reject) {
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = url;

        img.addEventListener('load', function() {
            resolve(img);
        });
        img.addEventListener('error', function(e) {
            reject(new Error('Error loading image'));
        });
    }).then(resizeImage);
}


export function loadDraggedImage(files) {
    let file = files[0];

    if (file && !file.type.match(/^image\//g)) {
        console.error(`'${file.type}' format is not supported`);
        return Promise.reject(new Error('Format not supported'));
    }

    let reader = new FileReader();
    return new Promise(function(resolve, reject) {
        reader.onload = (e) => {
            loadImageURL(e.target.result)
                .then(resolve)
                .catch(reject);
        };
        reader.onerror = (e) => {
            reject(new Error(e));
        };
        reader.readAsDataURL(file);
    });
}

function min(arr) {
    return arr.reduce((p, v) => p < v ? p : v );
}

function max(arr) {
  return arr.reduce((p, v) => p > v ? p : v );
}

export function forEachPixel(x1, x2, y1, y2, cb) {
    let yPx = y1;
    while (yPx <= y2) {
        let xPx = x1;
        while (xPx <= x2) {
            cb(xPx, yPx);
            xPx++;
        }
        yPx++;
    }
}

export function pixelMatrix(x1, x2, y1, y2) {
    let pixels = [];
    forEachPixel(x1, x2, y1, y2, function(xPx, yPx) {
        pixels.push([xPx, yPx]);
    });
    return pixels;
}

export function getPixelIdx(img, x, y) {
    var x = Math.max(Math.min(parseInt(x, 10), img.width - 1), 1);
    var y = Math.max(Math.min(parseInt(y, 10), img.height - 1), 1);
    var i = (y * img.width + x) * 4;
    return i;
}

export function getPixelChannels(img, x, y) {
    let i = getPixelIdx(img, x, y);
    return [img.data[i], img.data[i + 1], img.data[i + 2], img.data[i + 3]];
}


export function getPixelColor(img, x, y) {
    let i = getPixelIdx(img, x, y);
    let channels = img.data.slice(i, i + 3);
    return tinycolor(`rgb ${channels.join(' ')}`);
}


function polygonCentroid(points) {
    var l = points.length;

    return points.reduce((center, p, i) => {
        center[0] += p[0];
        center[1] += p[1];

        if (i === l - 1) {
            center[0] /= l;
            center[1] /= l;
        }

        return center;
    }, [0, 0]);
}


export function getCentroidAreaColor(img, t) {
    var points = t.filter((p) => p);
    var centroid = polygonCentroid(points);
    return getPixelColor(img, centroid[0], centroid[1]);
}

export function shapeSize(points) {
    var xVals = points.map(p => p[0]);
    var yVals = points.map(p => p[1]);
    let x1 = Math.round(min(xVals));
    let x2 = Math.round(max(xVals));
    let y1 = Math.round(min(yVals));
    let y2 = Math.round(max(yVals));

    return {
        x1, x2, y1, y2,
        height: y2 - y1,
        width: x2 - x1,
        bounds: [[x1, y1], [x2, y1], [x2, y2], [x1, y2]]
    }
}

export function getAverageAreaColor(img, shape) {
    let size = shapeSize(shape);
    let channels = [0, 0, 0];
    let total = 0;
    let {bounds, x1, x2, y1, y2} = size;

    let matrix = pixelMatrix(x1, x2, y1, y2);
    let colors = matrix.forEach(px => {
        if (!isPointInside(px, bounds)) { return; }

        let pxChs = getPixelChannels(img, px[0], px[1]);
        let alpha = pxChs[3];
        total += alpha;
        alpha /= 255;
        channels[0] += pxChs[0] * alpha;
        channels[1] += pxChs[1] * alpha;
        channels[2] += pxChs[2] * alpha;
    });

    for (var i = 0; i < 3; i++)
        channels[i] /= total;

    let rgb = channels.map(c => Math.round(Math.max(0, Math.min(255, c * 255))));
    let rgbChannels = total ? rgb : [255, 255, 255];
    return tinycolor(`rgb ${rgbChannels.join(' ')}`);
}