import React, { Component, PropTypes } from 'react';
import jsfeat from 'jsfeat';
import poissonDiscSampler from 'poisson-disc-sampler';

import PointExtractor from './base';
import { addPoints } from '../../actions/polyshaper';


export default class PoissonDiscExtractor extends PointExtractor {
    constructor(props) {
        super(props);
        this.state = {
            radius: 20
        };
    }

    render() {
        if (!this.isActive()) {
            return null;
        } else {
            return (
                <div className="Field">
                    <label>{`Radius (${this.state.radius})`}</label>
                    <input type="range"
                        min="3"
                        max="200"
                        step="1"
                        defaultValue={this.state.radius}
                        onChange={(e) => {
                            this.setState({
                                radius: e.target.value
                            })
                        }}
                    />
                </div>
            );
        }

    }
}

PoissonDiscExtractor.defaultProps = {
    name: 'Poisson Disc',
    task: function(e) {
        let polyshaper = this.props.polyshaper;

        let width = this.props.image.width;
        let height = this.props.image.height;

        let sampler = poissonDiscSampler(width, height, this.state.radius);
        let sample;
        let points = [];

        while ((sample = sampler())) {
            points.push(sample);
        }

        this.props.dispatch(addPoints(points));
    }
}