import React, { Component, PropTypes } from 'react';
import throttle from 'lodash.throttle';

import MouseTool from './base';
import { addPoints } from '../../actions/polyshaper';


let lastPointAdded;

export default class BrushMouseTool extends MouseTool {
    constructor(props) {
        super(props);
        this.state = {
            minDistance: 5
        }
    }

    render() {
        if (!this.isActive()) {
            return null;
        } else {
            return (
                <div className="Field">
                    <label>{`Min distance (${this.state.minDistance})`}</label>
                    <input type="range"
                        min="1"
                        max="100"
                        step="1"
                        defaultValue={this.state.minDistance}
                        onChange={(e) => {
                            this.setState({
                                minDistance: e.target.value
                            })
                        }}
                    />
                </div>
            );
        }
    }
}

BrushMouseTool.defaultProps = {
    name: 'brush',
    order: 2,
    keys: 'b',
    mouseEvent: 'stagemousedown',
    task: function(e) {
        let polyshaper = this.props.polyshaper;
        let stage = polyshaper.stage();
        let bitmap = polyshaper.bitmap();
        let { dispatch } = this.props;

        let bulkPoints = [];
        let addPointsDebounced = throttle(() => {
            let points = bulkPoints;
            bulkPoints = [];
            dispatch(addPoints(points));
        }, 20);

        stage.addEventListener('stagemousemove', (e) => {
            let local = stage.globalToLocal(e.stageX, e.stageY);
            if (!lastPointAdded ||
                Math.abs(local.x - lastPointAdded.x) > this.state.minDistance ||
                Math.abs(local.y - lastPointAdded.y) > this.state.minDistance
            ) {
                // save points at scale 1:1
                // being [0,0] the top-left corner of the image
                let x = (local.x - bitmap.x) / bitmap.scaleX;
                let y = (local.y - bitmap.y) / bitmap.scaleY;

                bulkPoints.push([x, y]);
                addPointsDebounced();

                lastPointAdded = local;
            }
        });

        stage.addEventListener('stagemouseup', function() {
            lastPointAdded = null;
            stage.removeAllEventListeners('stagemousemove');
        });
    }
}