import React, { Component, PropTypes } from 'react';
import shouldPureComponentUpdate from 'react-pure-render/function';

import Droparea from 'react-droparea';

const DEMO_IMAGES = [
    require("../images/demo/img-3.jpg").default,
    require("../images/demo/img-16.jpeg").default,
    require("../images/demo/img-17.jpeg").default,
    require("../images/demo/img-24.jpeg").default,
    // 'https://scontent-mrs1-1.cdninstagram.com/hphotos-xat1/t51.2885-15/e15/11280133_391323917738252_885130923_n.jpg',
    // 'http://40.media.tumblr.com/6b269ca388c7b63b58ce1d10ec7beeaf/tumblr_nrfiirwvEt1qzkyono1_1280.jpg',
    // 'https://images.unsplash.com/photo-1446080501695-8e929f879f2b',
    // 'http://40.media.tumblr.com/f4cda91e1762578ab0ce11559242aa8d/tumblr_o0iwgp2mTf1qbfmtho1_1280.jpg'
];

console.log(DEMO_IMAGES);


class DemoImage extends Component {
    static propTypes = {
        url: PropTypes.string.isRequired,   
        importImage: PropTypes.func.isRequired,
    }

    constructor() {
        super(...arguments);

        this._onClick = this._onClick.bind(this)
    }

    shouldComponentUpdate = shouldPureComponentUpdate;

    _onClick(event) {
        event.stopPropagation();

        this.props.importImage(this.props.url);
    }

    render() {
        return (
            <img
                src={this.props.url}
                onClick={this._onClick}
                height="100"
            />
        )
    }
}

export default class DropArea extends Component {

    static propTypes = {
        imageLoaded: PropTypes.bool.isRequired,
        children: PropTypes.node,
        onDrop: PropTypes.func.isRequired,
        importImage: PropTypes.func.isRequired,
    }

    static defaultProps = {
        children: null,
        imageLoaded: false,
    }

    render() {
        let { dispatch, imageLoaded, importImage } = this.props;
        let dropAreaClass = `Overlay Overlay--dropImage ${imageLoaded ? 'is-hidden' : ''}`;

        return (
            <Droparea
                onDrop={this.props.onDrop}
                className={'App-dropZone'}
                activeClassName={'is-active'}
                disableClick={imageLoaded}
                multiple={false}
            >
                <div className={dropAreaClass}>
                    <div className="Overlay-content">
                        <span className="Overlay-image">
                            <i className="BigIco BigIco-pics"></i>
                        </span>
                        <h1 className="Overlay-title">
                            {'Drop an image here'}
                        </h1>
                        <p className="Overlay-subtitle">
                            {'or click to select one from your filesystem.'}
                        </p>
                        <div className="Overlay-demoImages DemoImages">
                            <p className="DemoImages-title">You can also pick one of these images to start playing now!</p>
                            <ul>
                                {DEMO_IMAGES.map((url, idx) => (
                                    <li key={idx}><DemoImage url={url} importImage={importImage} /></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                {this.props.children}
            </Droparea>
        )
    }
}