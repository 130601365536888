import React, { Component, PropTypes } from 'react'
import shouldPureComponentUpdate from 'react-pure-render/function';

import { setMouseTool } from '../actions/polyshaper';

import mouseTools from './mouse-tools/';

export default class MouseToolsControls extends Component {

    static propTypes = {
        active: React.PropTypes.oneOfType([
            React.PropTypes.bool,
            React.PropTypes.string
        ]).isRequired,
        dispatch: PropTypes.func,
        polyshaper: PropTypes.func,
        points: React.PropTypes.oneOfType([
            React.PropTypes.array,
            PropTypes.arrayOf(PropTypes.array)
        ]),
        shapes: React.PropTypes.oneOfType([
            React.PropTypes.array,
            PropTypes.arrayOf(PropTypes.array)
        ])
    };

    shouldComponentUpdate = shouldPureComponentUpdate

    setMouseTool(tool) {
        if (this.props.active !== tool) {
            this.props.dispatch(setMouseTool(tool));
        }
    }

    render() {
        let {active, polyshaper, points, shapes, dispatch} = this.props;
        let className = (tool) => 'Button Button--tool' + (tool === active ? ' is-active' : '');
        let icoClassName = (tool) => {
            return `Ico Ico-${tool.toLowerCase()}` + (
                tool === active ? ' Ico--cWhite' : '')
        }

        return (
            <menu className={'MouseTools'}>
                {mouseTools.map((Tool) => {
                    let name = Tool.defaultProps.name;
                    let keyShortcut = Tool.defaultProps.keys;
                    return (
                        <button
                            className={className(name)}
                            key={name}
                            title={`${name} (${keyShortcut.toUpperCase()})`}
                            onClick={e => this.setMouseTool(name)}
                        >
                            <i className={icoClassName(name)}></i>
                        </button>);
                })}
                {mouseTools.map(Tool => {
                    return (
                        <Tool
                            key={Tool.defaultProps.name}
                            activeTool={active}
                            polyshaper={polyshaper}
                            dispatch={dispatch}
                            points={points}
                            shapes={shapes}
                        />)
                })}
            </menu>);
    }
}