import { takeEvery, takeLatest } from 'redux-saga';
import { take, put, call, select, fork, cancel } from 'redux-saga/effects';

import polyshaperTransform, { setColorWorkerData, colorize } from '../workers/polyshaper';
import * as imageUtils from '../utils/image';

import { shapesGenerated, stylesGenerated,
    mergePoint, setImage, importImageFailed } from './polyshaper';
import { showSpinner, hideSpinner } from './app';


function preparePolyshaperProps(props) {
    return {
        'shapeFill': props.shapeFill,
        'shapeFillColorMode': props.shapeFillColorMode,
        'shapeFillColor': props.shapeFillColor,
        'shapeFillOpacity': props.shapeFillOpacity,
        'colorManipulationInFill': props.colorManipulationInFill,
        'shapeStroke': props.shapeStroke,
        'shapeStrokeColorMode': props.shapeStrokeColorMode,
        'shapeStrokeColor': props.shapeStrokeColor,
        'shapeStrokeOpacity': props.shapeStrokeOpacity,
        'colorManipulationInStroke': props.colorManipulationInStroke,
        'colorManipulation': props.colorManipulation
    }
}

export function *watchAddPoints() {
    while (true) {
        let action = yield take('ADD_POINTS');
        let polyshaperProps = yield select((state) => {
            return state.polyshaper.present;
        });
        let points = yield call((currentPoints, newPoints) => {
            return [...currentPoints, ...newPoints];
        }, polyshaperProps.points, action.payload);
        let props = yield call(preparePolyshaperProps, polyshaperProps);
        let payload = yield call(polyshaperTransform, points, props);
        yield put(shapesGenerated(payload));
    }
}

export function *watchRemovePoints() {
    while (true) {
        let action = yield take('REMOVE_POINTS');
        let polyshaperProps = yield select((state) => {
            return state.polyshaper.present;
        });
        let toRemoveIdxs = action.payload;
        let points = polyshaperProps.points
            .filter((p, idx) => toRemoveIdxs.indexOf(idx) === -1);
        let props = yield call(preparePolyshaperProps, polyshaperProps);
        let payload = yield call(polyshaperTransform, points, props);
        yield put(shapesGenerated(payload));
    }
}

export function *watchMovePoint() {
    while (true) {
        let action = yield take('MOVE_POINT');
        let polyshaperProps = yield select((state) => {
            return state.polyshaper.present;
        });
        let toRemoveIdx = action.payload.pointIdx;
        let points = polyshaperProps.points
            .map((p, idx) => {
                if (idx === toRemoveIdx) {
                    return action.payload.point;
                }
                return p;
            });
        let props = yield call(preparePolyshaperProps, polyshaperProps);
        let payload = yield call(polyshaperTransform, points, props);
        yield put(shapesGenerated(payload));
    }
}


function delay(ms) {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, ms);
    }).then(() => null);
}

function *handleColorChange() {
    try {
        yield call(delay, 60);

        let polyshaperProps = yield select((state) => {
            return state.polyshaper.present;
        });
        let shapes = polyshaperProps.shapes;
        let props = yield call(preparePolyshaperProps, polyshaperProps);
        let payload = yield call(colorize, shapes, props);
        yield put(stylesGenerated(payload));
    } catch (err) {
        // err is an instanceof SagaCancellationException
        // see docs here http://yelouafi.github.io/redux-saga/docs/advanced/TaskCancellation.html
    }
}

export function *watchColorChange() {
    let task;

    while (true) {
        yield take([
            'RESET_COLOR_MANIPULATION',
            'SET_COLOR_MANIPULATION_IN_STROKE',
            'SET_COLOR_MANIPULATION_IN_FILL',
            'SET_COLOR_MANIPULATION',
            'SET_SHAPE_FILL',
            'SET_SHAPE_FILL_COLOR',
            'SET_SHAPE_FILL_COLOR_MODE',
            'SET_SHAPE_FILL_OPACITY',
            'SET_SHAPE_STROKE',
            'SET_SHAPE_STROKE_COLOR',
            'SET_SHAPE_STROKE_COLOR_MODE',
            'SET_SHAPE_STROKE_OPACITY'
        ]);

        if (task) { yield cancel(task); }
        task = yield fork(handleColorChange);
    }
}

export function *watchImportImage() {
    while (true) {
        let action = yield take('IMPORT_IMAGE');
        yield put(showSpinner());
        let {image, error} = yield call(imageUtils.loadImage, action.payload);

        if (error) {
            yield put(importImageFailed(error));
        } else {
            let imageData = yield call(imageUtils.getImageData, image);
            yield put(setImage({image, imageData: imageData.data}));
            yield call(setColorWorkerData, {
                width: imageData.width,
                height: imageData.height,
                data: imageData.data
            });
        }

        yield put(hideSpinner());
    }
}


export default function *root() {
    yield fork(watchAddPoints);
    yield fork(watchRemovePoints);
    yield fork(watchMovePoint);
    yield fork(watchImportImage);
    yield fork(watchColorChange);
}