import {createStore, compose, combineReducers, applyMiddleware} from 'redux';
import promiseMiddleware from 'redux-promise';
import {enableBatching} from 'redux-batched-actions';
import undoable from 'redux-undo';
import createSagaMiddleware from 'redux-saga';

import polyshaperReducer from './reducers/polyshaper';
import appReducer from './reducers/app';
import {mixpanelMiddleware} from './utils/mixpanel';
import sagas from './actions/sagas';

import { persistState } from 'redux-devtools';


// TODO: add wrapper to actions to allow indicating if action is undoable
const UNDOABLE_ACTIONS = [
    'IMPORT_IMAGE',
    'ADD_POINTS',
    'MOVE_POINT',
    'REMOVE_POINTS',
    'CLEAR_POINTS',
    'SET_COLOR_MANIPULATION',
    'SET_COLOR_MANIPULATION_IN_STROKE',
    'SET_COLOR_MANIPULATION_IN_FILL',
    'RESET_COLOR_MANIPULATION',
    'SET_SHAPE_STROKE',
    'SET_SHAPE_STROKE_OPACITY',
    'SET_SHAPE_STROKE_COLOR_MODE',
    'SET_SHAPE_STROKE_COLOR',
    'SET_SHAPE_FILL',
    'SET_SHAPE_FILL_OPACITY',
    'SET_SHAPE_FILL_COLOR_MODE',
    'SET_SHAPE_FILL_COLOR',
    'SET_IMAGE_VISIBILITY',
    'SET_IMAGE_OPACITY'
];

const UNDO_CONFIG = {
    limit: 100,
    filter: (action, currentState, previousState) => {
        return UNDOABLE_ACTIONS.indexOf(action.type) > -1;
    }
};

let reducers = {
    app: enableBatching(appReducer),
    polyshaper: undoable(enableBatching(polyshaperReducer), UNDO_CONFIG)
};

let sagaMiddleware = createSagaMiddleware();

if (process.env.DEV && process.env.DEV_REDUX_DEVTOOLS) {
    let DevTools = require('./containers/DevTools').default;
    var enhancer = compose(
        applyMiddleware(sagaMiddleware),
        applyMiddleware(promiseMiddleware),
        DevTools.instrument(),
        persistState(
            window.location.href.match(/[?&]debug_session=([^&]+)\b/))
    );
} else {
    var enhancer = compose(
        applyMiddleware(sagaMiddleware),
        applyMiddleware(promiseMiddleware),
        // applyMiddleware(mixpanelMiddleware({})) // only track mixpanel actions in development
    );
}

let rootReducer = combineReducers(reducers);
let store = createStore(rootReducer, {}, enhancer);

sagaMiddleware.run(sagas);

export default store;