import React, { Component, PropTypes } from 'react';

import MouseTool from './base';

import { addPoints } from '../../actions/polyshaper';


export default class PointMouseTool extends MouseTool {}

PointMouseTool.defaultProps = {
    name: 'point',
    order: 1,
    keys: 'p',
    mouseEvent: 'click',
    task: function(e) {
        let polyshaper = this.props.polyshaper;
        let bitmap = polyshaper.bitmap();
        let local = polyshaper.stage().globalToLocal(e.stageX, e.stageY);
        // save points at scale 1:1
        // being [0,0] the top-left corner of the image
        let x = (local.x - bitmap.x) / bitmap.scaleX;
        let y = (local.y - bitmap.y) / bitmap.scaleY;
        this.props.dispatch(addPoints([[x, y]]));
    }
}