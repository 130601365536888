import store from './store';

import { ActionCreators } from 'redux-undo';
import mouseTools from './components/mouse-tools/';
import { setMouseTool, toggleShapeFill, toggleShapeStroke, toggleImage,
        increaseZoom, decreaseZoom, resetZoom } from './actions/polyshaper';

import { listener } from './components/KeyboardShortcuts';


listener.simple_combo('cmd z', () => store.dispatch(ActionCreators.undo()));
listener.simple_combo('shift cmd z', () => store.dispatch(ActionCreators.redo()));

mouseTools.forEach((Tool) => {
    let name = Tool.defaultProps.name;
    let keyShortcut = Tool.defaultProps.keys;
    
    if (Tool.defaultProps.handledByDOM) {
        return;
    }

    listener.simple_combo(keyShortcut, () => store.dispatch(setMouseTool(name)))
});

listener.simple_combo('cmd f', () => store.dispatch(toggleShapeFill()));
listener.simple_combo('cmd k', () => store.dispatch(toggleShapeStroke()));
listener.simple_combo('cmd i', () => store.dispatch(toggleImage()));
listener.simple_combo('cmd +', () => store.dispatch(increaseZoom()));
listener.simple_combo('cmd =', () => store.dispatch(increaseZoom()));
listener.simple_combo('cmd -', () => store.dispatch(decreaseZoom()));
listener.simple_combo('cmd 0', () => store.dispatch(resetZoom()));
