import React, { Component, PropTypes } from 'react'
import { listener } from '../../components/KeyboardShortcuts';

import { setMouseTool } from '../../actions/polyshaper';


let prevTool;

let propTypes = {
    name: PropTypes.string.isRequired,
    keys: PropTypes.string,
    order: PropTypes.number,
    mouseEvent: PropTypes.string.isRequired,
    whilePressed: PropTypes.bool,
    task: PropTypes.func.isRequired,

    points: React.PropTypes.oneOfType([
        React.PropTypes.array,
        PropTypes.arrayOf(PropTypes.array)
    ]),
    shapes: React.PropTypes.oneOfType([
        React.PropTypes.array,
        PropTypes.arrayOf(PropTypes.array)
    ]),
    dispatch: PropTypes.func.isRequired,
    polyshaper: PropTypes.func.isRequired,
    activeTool: PropTypes.string.isRequired
};

export default class MouseTool extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prevTool: props.activeTool,
            enabled: true
        }
        this.init();
    }

    componentWillReceiveProps(nextProps) {
        if (this.isActive() && nextProps.activeTool !== this.props.name) {
            this.onDisable();
        }
    }

    onDisable() {} // to be overriden by inheriters

    init() {
        this.bindMouseEvents();
        this.bindKeyboardEvents();
    }

    isActive() {
        return this.props.activeTool === this.props.name;
    }

    bindMouseEvents() {
        let stage = this.props.polyshaper.stage();
        
        stage.addEventListener(this.props.mouseEvent, function(e) {
            if (!this.isActive()) { return; }
            this.props.task.call(this, e);
        }.bind(this));
    }

    bindKeyboardEvents() {
        if (this.props.whilePressed) {
            this.bindWhilePressedKeyboardEvent();
        }
    }

    bindWhilePressedKeyboardEvent() {
        if (!this.props.keys) { return; }

        
        // let keyDown = false;
        // // const KEYCODE = 65; // a
        // const KEYCODE = 32; //spacebar
        // document.body.addEventListener('keydown', (e) => {
        //     if (!keyDown && e.keyCode == KEYCODE) {
        //         console.log('keyDown', e.cancelable);
        //         e.stopPropagation();
        //         this.setMouseTool();
        //         keyDown = true;
        //     }

        //     e.preventDefault();
        // }, true)

        // document.body.addEventListener('keypress', (e) => {
        //     if (e.keyCode == KEYCODE) {
        //         // console.log('keypress', e.cancelable);
        //         e.preventDefault();
        //         // e.stopPropagation();
        //     }
        // }, true)

        // document.body.addEventListener('keyup', (e) => {
        //     if (keyDown && e.keyCode == KEYCODE) {
        //         console.log('keyup', e.cancelable);
        //         e.preventDefault();
        //         e.stopPropagation();
        //         this.setMouseTool(this.state.prevTool);
        //         keyDown = false;
        //     }
        // }, true)

        // return;

        listener.register_combo({
            'keys': this.props.keys,
            'this': this,
            'prevent_repeat': true,
            'prevent_default': true,
            'on_keydown': (e) => {
                // e.preventDefault();
                // console.log('>>>>', 'keydown');
                this.setMouseTool();
                return false;
            },
            'on_release': () => {
                // console.log('>>>>', 'release');
                this.setMouseTool(this.state.prevTool);
            }
        });
    }

    setMouseTool(tool) {
        tool || (tool = this.props.name);
        this.setState({ prevTool: this.props.activeTool });
        this.props.dispatch(setMouseTool(tool));
    }

    render() {
        return null;
    }
}

MouseTool.propTypes = propTypes;
MouseTool.defaultProps = {
    whilePressed: false
};