import React from 'react';
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store  from './store';
import { trackEvent }  from './utils/mixpanel';
import App from './app';

import appmenu from './appmenu';

// import menu from './appmenu';
// TODO: move this to the template
// import 'file?name=favicon.ico!./images/favicon.ico';


trackEvent({
    eventName: 'OPEN_APP'
});


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, document.getElementById('root'));
