import React, { Component, PropTypes } from 'react';
import jsfeat from 'jsfeat';

import createjs from 'createjs';


import BrushMouseTool from './mouse-tools/brush-tool';
import FastCornersPointExtractor from './point-extractors/fastcorners-extractor';
import toSVG from '../utils/to-svg';
import download from '../utils/download';

import { batchActions } from 'redux-batched-actions';
import { setZoom, setPanCoords, exportImage } from './../actions/polyshaper';


const defaults = {
    width: 500,
    height: 500,
    dispatch: function() {},
    zoom: [1, [0, 0]],
    panCoords: [0, 0]
}

export const COLOR_PICKING_MODES = {
    CENTROID: 'centroid',
    AVERAGE: 'average',
    CUSTOM: 'custom'
}

export const defaultProps = {
    points: [],
    shapes: [],
    styles: [],
    zoom: [1, [0, 0]], // zoom, [stage.regX, stage.regY]
    panCoords: [0, 0],
    tool: BrushMouseTool.defaultProps.name,
    pointExtractor: FastCornersPointExtractor.defaultProps.name,
    shapeStroke: true,
    shapeStrokeColorMode: COLOR_PICKING_MODES.CUSTOM,
    shapeStrokeColor: '#000000',
    shapeStrokeOpacity: 1,
    shapeFill: true,
    shapeFillColorMode: COLOR_PICKING_MODES.CENTROID,
    shapeFillColor: '#000000',
    shapeFillOpacity: 1,
    image: new Image(),
    imageData: {},
    imageVisibility: true,
    imageOpacity: 1,
    colorManipulationInStroke: true,
    colorManipulationInFill: true,
    colorManipulation: {
        spin: 0,
        saturate: 0,
        desaturate: 0,
        lighten: 0,
        darken: 0,
        brighten: 0
    }
}

const renderTriggers = [
    'shapes', 'styles', 'shapeStroke', 'shapeStrokeColorMode', 'shapeStrokeColor',
    'shapeStrokeOpacity', 'shapeFill', 'shapeFillColorMode', 'shapeFillColor',
    'shapeFillOpacity', 'image', 'imageVisibility', 'imageOpacity', 'width',
    'height', 'colorManipulation', 'colorManipulationInStroke',
    'colorManipulationInFill'];

const pointHandlers = [];
const pointHandlerRadius = 2;



export default function PolyShaper(id, props) {
    let el = document.getElementById(id);
    let stage = new createjs.Stage(id);
    let pointsLayer = new createjs.Container();
    let toolsLayer = new createjs.Container();
    let artboard = new createjs.Container();
    let bitmap = new createjs.Bitmap();
    let { dispatch } = props;
    let SHAPES = new Map();
    let COLOR_CACHE = new Map();

    props = Object.assign({}, defaults, defaultProps, props)


    function canvas() { return canvas; };

    //
    // EVENTS
    //

    // zoom
    function wheelHandler(e) {
        let scaleFactor = 1.1;
        var delta = e.wheelDelta ? e.wheelDelta / 40 : e.detail ? -e.detail : 0;
        if (!delta) { return; }

        let local = stage.globalToLocal(stage.mouseX, stage.mouseY);
        var factor = Math.pow(scaleFactor, delta);

        props.dispatch(batchActions([
            setPanCoords(stage.mouseX, stage.mouseY),
            setZoom(stage.scaleX * factor, [local.x, local.y])
        ]));


        return e.preventDefault() && false;
    }
    el.addEventListener('mousewheel', wheelHandler, false);
    el.addEventListener('DOMMouseScroll', wheelHandler, false);


    //
    // METHODS
    //

    canvas.setSize = function() {
        el.setAttribute('width', props.width);
        el.setAttribute('height', props.height);
    }

    canvas.stage = function() {
        return stage;
    }

    canvas.bitmap = function() {
        return bitmap;
    }

    canvas.toolsLayer = function() {
        return toolsLayer;
    }

    canvas.getCanvasShapePoints = function(shape) {
        return SHAPES.get(shape.id);
    }

    canvas.getShapesObjects = function(shapes) {
        return shapes.map(canvas.createShape);
    }

    canvas.drawShapes = function() {
        // let t0 = performance.now();
        let shapes = props.shapes;
        artboard.removeAllChildren();
        SHAPES.clear();

        if (!shapes.length) { return; };

        let polygons = canvas.getShapesObjects(shapes);
        artboard.addChild.apply(artboard, polygons);

        // let t1 = performance.now();
        // console.log('draw triangles took: ', (t1 - t0), 'ms');
    }



    canvas.pointToArtboard = function(point) {
        return [
            ((point[0] * bitmap.scaleX) + bitmap.x),
            ((point[1] * bitmap.scaleY) + bitmap.y)
        ];
    }

    canvas.pointsToArtboard = function(points) {
        return points.map(canvas.pointToArtboard);
    }

    function _shapeToTriangle(shape, t) {
        var points = t.filter((p) => p);
        if (!points.length) { return shape; }

        let firstPoint = points[0];
        let path = shape.graphics.moveTo(firstPoint[0], firstPoint[1]);
        points.slice(1).forEach((p) => path = path.lineTo(p[0], p[1]));
        path = path.closePath();
        return shape;
    }

    canvas.createShape = function(shapePoints, idx) {
        let styles = props.styles[idx];
        let fillColor, strokeColor;
        let shape = new createjs.Shape();
        let scaledPoints = canvas.pointsToArtboard(shapePoints);

        // hit area
        let hit = new createjs.Shape();
        hit.graphics.beginFill('black');
        _shapeToTriangle(hit, scaledPoints);
        shape.hitArea = hit;

        if (props.shapeFill) {
            fillColor = styles.fill;
            shape.graphics.beginFill(fillColor);
        }

        if (props.shapeStroke) {
            strokeColor = styles.stroke;
            shape.graphics
                .setStrokeStyle(1, 'butt', 'miter', 10, true)
                .beginStroke(strokeColor);
        }

        _shapeToTriangle(shape, scaledPoints);
        // cache relation between shape and its points
        SHAPES.set(shape.id, shapePoints);

        return shape;
    }

    canvas.drawShapePointsHandlers = function(points = []) {
        pointsLayer.removeAllChildren();
        if (!points.length) { return; }
        let handlers = points.map(function(point) {
            return [canvas.addPointHandler(point), point];
        });
        stage.update();
        return handlers;
    }

    canvas.addPointHandler = function(point) {
        let lens;
        let bmp;

        let circle = new createjs.Shape();
        circle.graphics.beginFill('red').drawCircle(0, 0, pointHandlerRadius);
        circle.x = point[0];
        circle.y = point[1];
        circle.scaleX = circle.scaleY = 1 / stage.scaleX;
        pointsLayer.addChild(circle);
        pointHandlers.push(circle);

        return circle;
    }

    canvas.resizePointHandlers = function() {
        pointHandlers.forEach(function(circle) {
            circle.scaleX = circle.scaleY = 1 / stage.scaleX;
        });
    };

    canvas.drawImage = function() {
        // empty color cache
        COLOR_CACHE.clear();

        let img = props.image;
        bitmap.image = props.image;
        bitmap.alpha = props.imageOpacity;

        // scale image to canvas
        let hRatio = props.width / img.width;
        let vRatio = props.height / img.height;
        let ratio  = Math.min(hRatio, vRatio);
        let centerShift_x = ( props.width - (img.width * ratio) ) / 2;
        let centerShift_y = ( props.height - (img.height * ratio) ) / 2;

        bitmap.x = centerShift_x;
        bitmap.y = centerShift_y;
        bitmap.scaleX = (img.width * ratio) / img.width;
        bitmap.scaleY = (img.height * ratio) / img.height;

        artboard.regX = 0;
        artboard.regY = 0;
        artboard.x = pointsLayer.x = 0;
        artboard.y = pointsLayer.y = 0;

        stage.addChild(bitmap);
        stage.addChild(artboard);
        stage.addChild(pointsLayer);
        stage.addChild(toolsLayer);
    }

    canvas.props = function(newProps) {
        newProps && (props = Object.assign({}, props, newProps));
        return props;
    }

    canvas.update = function() {
        canvas.drawShapes();
        canvas.setZoom();
        canvas.setPosition();
        stage.update();
    }

    canvas.setZoom = function() {
        let zoom = props.zoom;

        if (zoom[1]) {
            stage.regX = zoom[1][0];
            stage.regY = zoom[1][1];
        } else { // automatically center
            // TOO: fix this ugly thing
            let local = stage.globalToLocal(props.width / 2, props.height / 2);
            props.dispatch(batchActions([
                setPanCoords(props.width / 2, props.height / 2),
                setZoom(zoom[0], [local.x, local.y])
            ]));

            return;
        }

        stage.scaleX = stage.scaleY = zoom[0];

        canvas.resizePointHandlers();
    }

    canvas.setPosition = function() {
        let panCoords = props.panCoords;
        stage.x = panCoords[0];
        stage.y = panCoords[1];
    }

    function _doPropChanged(propName, oldProps, newProps) {
        return newProps && newProps[propName] &&
            oldProps[propName] !== newProps[propName];
    }

    canvas.propsUpdate = function(newProps = {}) {
        const oldProps = props;
        let changed = 0;

        // override current settings with new settings
        canvas.props(newProps);

        // redraw image if changed
        if (_doPropChanged('image', oldProps, newProps)) {
            canvas.drawImage();
            changed++;
        }

        if (_doPropChanged('zoom', oldProps, newProps)) {
            canvas.setZoom();
            changed++;
        }

        if (_doPropChanged('panCoords', oldProps, newProps)) {
            canvas.setPosition();
            changed++;
        }

        // resize canvas if window size changed
        if (_doPropChanged('width', oldProps, newProps) ||
            _doPropChanged('height', oldProps, newProps)) {
            canvas.setSize(); // TODO: move to drawImage?
            canvas.drawImage();
            changed++;
        }

        let renderProps = Object.keys(newProps).filter(propKey => {
                return (renderTriggers.indexOf(propKey) > -1 &&
                    oldProps[propKey] !== newProps[propKey]);
            });

        if (!renderProps.length) {
            changed && stage.update();
            return;
        }

        // ensure image-related attributes update
        bitmap.alpha = props.imageOpacity;
        bitmap.visible = props.imageVisibility;

        canvas.update();
    }

    canvas.removePointHandlers = function() {
        pointsLayer.removeAllChildren();
    }

    function _exportName(ext) {
        let basename = 'polyshaper-export-';
        return basename + (new Date().getTime()) + ext;
    }

    canvas.prepareForExport = function() {
        canvas.removePointHandlers();
        canvas.update();
    }

    canvas.exportSVG = function(link) {
        canvas.prepareForExport();

        let shapes = props.shapes
            .map(canvas.pointsToArtboard);

        let exported = toSVG(shapes, props.styles, bitmap, {
            width: stage.canvas.width,
            height: stage.canvas.height
        });

        props.dispatch(exportImage({
            data: exported,
            filename: _exportName('.svg'),
            mimetype: 'image/svg+xml'
        }));
    }

    canvas.exportJPG = function(link) {
        canvas.prepareForExport();

        // FIXME: this is a hack to not call dispatcher to reset zoom.
        // Position and scale are recoveredd in `afterExport()` from props,
        // which are unchanged
        stage.regX = stage.regY = stage.x = stage.y = 0;
        stage.scaleX = stage.scaleY = 1;
        stage.update();

        props.dispatch(exportImage({
            data: stage.toDataURL('#FFF', 'image/jpeg'),
            filename: _exportName('.jpg'),
            mimetype: 'image/jpeg'
        }));
    }

    // INIT
    canvas.setSize();

    return canvas;
}