import React, { Component, PropTypes } from 'react';

import MouseTool from './base';

import { movePoint } from '../../actions/polyshaper';


export default class SelectMouseTool extends MouseTool {
    constructor(props) {
        super(props);
        this.state = {
            selectedShape: false,
            isMoving: false
        }
    }

    onDisable() {
        this.deselectShape();
    }

    deselectShape() {
        let {polyshaper} = this.props;
        let stage = polyshaper.stage();
        stage.enableMouseOver(0);
        polyshaper.removePointHandlers();
        polyshaper.update();
        this.setState({selectedShape: false});
    }

    selectShape(shapePoints) {
        let {polyshaper, dispatch} = this.props;
        let stage = polyshaper.stage();
        let bitmap = polyshaper.bitmap();
        let scaledPoints = polyshaper.pointsToArtboard(shapePoints);

        stage.enableMouseOver(10);
        this.setState({selectedShape: true});
        let handlers = polyshaper.drawShapePointsHandlers(scaledPoints);
        handlers.forEach((handler, i) => {
            let handlerShape = handler[0];
            let sourcePoint = shapePoints[i];
            handlerShape.cursor = 'move';

            let initialScale = 1;
            handlerShape.on('rollover', (e) => {
                initialScale = handlerShape.scaleX;
                handlerShape.scaleX = handlerShape.scaleY = 3 / stage.scaleX;
                stage.update();
            });

            handlerShape.on('rollout', (e) => {
                handlerShape.scaleX = handlerShape.scaleY = initialScale;
                stage.update();
            });

            handlerShape.on('pressmove', (e) => {
                this.setState({isMoving: true});

                let shapeIdx = this.props.shapes.indexOf(shapePoints);
                let shapePointIdx = shapePoints.indexOf(sourcePoint);
                let pointIdx = ((Math.max(shapeIdx, 0) * shapePoints.length) +
                    Math.max(shapePointIdx, 0));

                if (pointIdx === -1) { return; }

                let bitmapBounds = bitmap.getTransformedBounds();
                let local = stage.globalToLocal(e.stageX, e.stageY);
                let x = (local.x - bitmap.x) / bitmap.scaleX;
                let y = (local.y - bitmap.y) / bitmap.scaleY;

                let scaledCoords = polyshaper.pointToArtboard([x, y]);
                handlerShape.x = scaledCoords[0];
                handlerShape.y = scaledCoords[1];

                let newPoint = [x, y];
                dispatch(movePoint(pointIdx, newPoint));
                sourcePoint = newPoint;
            });

            handlerShape.on('pressup', (e) => {
                this.setState({isMoving: false});
            });
        });
    }
}

SelectMouseTool.defaultProps = {
    name: 'select',
    order: 4,
    keys: 's',
    mouseEvent: 'click',
    task: function(e) {
        let {polyshaper} = this.props;
        let stage = polyshaper.stage();
        let local = stage.globalToLocal(e.stageX, e.stageY);

        if (this.state.isMoving) { return; }

        if (this.state.selectedShape) { this.deselectShape(); }

        let t = stage.getObjectUnderPoint(local.x, local.y, 0);
        if (!t) { return; }

        let shapePoints = polyshaper.getCanvasShapePoints(t);

        if (!shapePoints) { return; }

        this.selectShape(shapePoints);
    }
}