import { handleActions } from 'redux-actions';

const NO_MODAL_VALUE = '';

const defaults = {
    modal: NO_MODAL_VALUE,
    spinner: false
};

const reducer = handleActions({
    'SHOW_MODAL': (state, action) => {
        return {
            ...state,
            modal: action.payload
        }
    },
    'HIDE_MODAL': (state, action) => {
        return {
            ...state,
            // just hide it if its triggered from itself
            modal: state.modal === action.payload ? NO_MODAL_VALUE : state.modal
        }
    },
    'SHOW_SPINNER': (state, action) => {
        return {
            ...state,
            spinner: action.payload
        }
    },
    'HIDE_SPINNER': (state, action) => {
        return {
            ...state,
            spinner: action.payload
        }
    }
}, defaults);


export default reducer;