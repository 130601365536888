import chunk from 'lodash.chunk';


// Usage
// let send = createSender(worker);
// send(input).then((output) => console.log(output));

export function createSender(worker) {
    let listeners = [];

    worker.onmessage = function (evt) {
        if (listeners[evt.data.id]) { listeners[evt.data.id](evt.data.data); }
        delete listeners[evt.data.id];
    };

    return function(type = 'data', data) {
        return new Promise((resolve, reject) => {
            listeners.push((data) => {
                resolve(data);
            });
            worker.postMessage({
                id: listeners.length - 1,
                type: type,
                payload: data
            });
        });
    };
}


// Usage:
//
// const colorWorker = parallelize(ColorPickingWorker, 4);
//
// colorWorker.send({myData: foo, bar: 2})
//    .then((response) => console.log(response));
//
// WORKER RECEIVES
// {id: 3, type: 'data', payload: {myData: foo, bar: 2}}

export function parallelize(WorkerClass, n = 4) {
    let workers = new Array(n).fill(null).map(() => new WorkerClass());
    let senders = workers.map((worker) => createSender(worker));

    return {
        send: function(data, props) {
            let chunks = data.length > 3000 ?
                chunk(data, Math.ceil(data.length / n)) :
                data.length ? [data] : [];

            let promises = chunks.map(
                (cData, i) => senders[i]('data', {shapes: cData, props}));
            return Promise.all(promises);
        },
        set: function(data) {
            let promises = workers.map(
                (w, i) => senders[i]('set', data));
            return Promise.all(promises);
        }
    }
}