import { createAction } from 'redux-actions';
import { trackEvent } from '../utils/mixpanel';
import 'whatwg-fetch';

export let showModal = createAction('SHOW_MODAL');
export let hideModal = createAction('HIDE_MODAL');

export let showSpinner = createAction('SHOW_SPINNER', () => true);
export let hideSpinner = createAction('HIDE_SPINNER', () => false);


export function _sendMail(data) {
    return fetch(process.env.ENDPOINT_FEEDBACK, {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then((response) => {
        trackEvent({eventName: 'SEND_FEEDBACK'});
        return response.json();
    });
}