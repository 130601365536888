import React, { Component, PropTypes } from 'react';
import jsfeat from 'jsfeat';

import PointExtractor from './base';
import { addPoints } from '../../actions/polyshaper';


export default class FastCornersExtractor extends PointExtractor {
    constructor(props) {
        super(props);
        this.state = {
            threshold: 30
        };
    }

    render() {
        if (!this.isActive()) {
            return null;
        } else {
            return (
                <div className="Field">
                    <label>{`Threshold (${this.state.threshold})`}</label>
                    <input type="range"
                        min="10"
                        max="40"
                        step="1"
                        defaultValue={this.state.threshold}
                        onChange={(e) => {
                            this.setState({
                                threshold: e.target.value
                            })
                        }}
                    />
                </div>
            );
        }

    }
}

FastCornersExtractor.defaultProps = {
    name: 'Fast Corners',
    task: function(e) {
        let polyshaper = this.props.polyshaper;

        let width = this.props.image.width;
        let height = this.props.image.height;
        let img_u8 = new jsfeat.matrix_t(
            width, height, jsfeat.U8_t | jsfeat.C1_t);
        let corners = [];
        let border = 0;

        // you should use preallocated point2d_t array
        let i = width * height;
        while (--i >= 0) {
            corners[i] = new jsfeat.keypoint_t(0, 0, 0, 0);
        }

        jsfeat.fast_corners.set_threshold(this.state.threshold);

        // perform detection
        jsfeat.imgproc.grayscale(this.props.imageData, width, height, img_u8);
        // returns the amount of detected corners
        let count = jsfeat.fast_corners.detect(img_u8, corners, 5);
        let points = corners.slice(0, count).map(function(p) {
            return [p.x, p.y];
        });

        this.props.dispatch(addPoints(points));
    }
}