import React, { Component, PropTypes } from 'react';

import debounce from 'lodash.debounce';
import { connect } from 'react-redux'

import PolyShaper, {defaultProps} from './Polyshaper';
import Controls from './Controls';
import { ModalButtons } from './Modals';

import { getWindowSize } from '../utils/dom';



const CONTROLS_WIDTH = 300;

class Canvas extends Component {

    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        controls: PropTypes.object.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = this.getWindowSizeAsState();
    }

    componentDidMount() {
        let canvasEl = this.refs.canvas;

        this.polyshaper = PolyShaper(canvasEl.id, this.getPolyshaperProps());

        window.addEventListener('resize', this.handleResize);

        this.forceUpdate();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.controls !== nextProps) {
            return true;
        }

        const widthChanged = this.state.windowWidth !== nextState.windowWidth;
        const heightChanged = this.state.windowHeight !== nextState.windowHeight;

        if (widthChanged || heightChanged) {
            return true;
        }

        return false;
    };

    componentDidUpdate() {
        this.polyshaper.propsUpdate(Object.assign(this.getPolyshaperProps(), {
            ...this.props.controls
        }));
    }

    componentWillUnmount() {
       window.removeEventListener('resize', this.handleResize);
    }

    getPolyshaperProps() {
        return {
            width: this.state.windowWidth - CONTROLS_WIDTH,
            height: this.state.windowHeight,
            dispatch: this.props.dispatch
        };
    }

    getWindowSizeAsState() {
        let windowSize = getWindowSize();
        return {
            windowWidth: windowSize.width,
            windowHeight: windowSize.height
        };
    }

    handleResize = debounce((e) => {
        this.setState(this.getWindowSizeAsState());
    }, 200);

    render() {
        let { controls, dispatch } = this.props;

        return (<div className="Polyshaper">
            <div className="App-controls">
                {this.polyshaper ?
                    <Controls
                        {...controls}
                        polyshaper={this.polyshaper}
                        dispatch={dispatch}
                    /> : null
                }
                <div className="App-links">
                    <ModalButtons dispatch={dispatch} />
                </div>
            </div>
            <div className={`App-canvas Cursor--${controls.tool}`}>
                <canvas
                    ref="canvas"
                    id="artboard"
                />
            </div>
        </div>);
    }
}


Canvas.defaultProps = defaultProps;

function stateToProps(state) {
    const presentControls = state.polyshaper.present; // using redux-undo

    return {
        controls: presentControls
    }
}

export default connect(stateToProps)(Canvas);