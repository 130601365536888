import React, { Component, PropTypes } from 'react';
import jsfeat from 'jsfeat';

import PointExtractor from './base';
import { addPoints } from '../../actions/polyshaper';


export default class YAPE06Extractor extends PointExtractor {
    constructor(props) {
        super(props);
        this.state = {
            min_eigen_value_threshold: 150
        };
    }

    render() {
        if (!this.isActive()) {
            return null;
        } else {

            let {border, laplacian_threshold,
                 min_eigen_value_threshold} = this.state;

            return (
                <div>
                <div className="Field">
                    <label>
                        {[
                            'Threshold',
                            ` (${min_eigen_value_threshold})`
                        ]}
                    </label>
                    <input type="range"
                        min="20"
                        max="300"
                        step="1"
                        defaultValue={min_eigen_value_threshold}
                        onChange={(e) => {
                            this.setState({
                                min_eigen_value_threshold: e.target.value
                            })
                        }}
                    />
                </div>
                </div>
            );
        }

    }
}

YAPE06Extractor.defaultProps = {
    name: 'YAPE06',
    task: function(e) {
        let polyshaper = this.props.polyshaper;
        let bitmap = polyshaper.bitmap();

        let width = this.props.image.width;
        let height = this.props.image.height;
        let img_u8 = new jsfeat.matrix_t(
            width, height, jsfeat.U8_t | jsfeat.C1_t);
        let corners = [];
        let border = 0, laplacian_threshold = 100;
        let { min_eigen_value_threshold } = this.state;

        jsfeat.yape06.laplacian_threshold = laplacian_threshold;
        jsfeat.yape06.min_eigen_value_threshold = min_eigen_value_threshold;

        let i = width * height;
        while (--i >= 0) {
            corners[i] = new jsfeat.keypoint_t(0, 0, 0, 0);
        }

        jsfeat.imgproc.grayscale(this.props.imageData, width, height, img_u8);
        let count = jsfeat.yape06.detect(img_u8, corners, border);

        let points = corners.slice(0, count).map(function(p) {
            return [p.x, p.y];
        });

        this.props.dispatch(addPoints(points));
    }
}