import { parallelize } from '../utils/workers';

import DelaunayWorker from './triangulate.worker';
import ColorPickingWorker from './color-picking.worker';

const delaunayWorker = new DelaunayWorker();
const colorWorker = parallelize(ColorPickingWorker, 4);


function triangulate(points) {
    return new Promise((resolve, reject) => {
        let handler = (evt) => {
            delaunayWorker.removeEventListener('message', handler);

            if (!evt.data) {
                reject(evt);
                return;
            }

            resolve(evt.data);
        }

        delaunayWorker.addEventListener('message', handler);
        delaunayWorker.postMessage({ points });
    });
}

export function colorize(shapes, props) {
    return colorWorker.send(shapes, props).then((styleChunks) => {
        return styleChunks.reduce((total, sChunk) => {
            return total.concat(sChunk);
        }, []);
    });
}


export default function polyshaperTransform(points, props) {
    return new Promise((resolve, reject) => {
        triangulate(points)
            .then((shapes) => {
                return colorize(shapes, props).then((styles) => {
                    return { points, shapes, styles }
                });
            })
            .then(resolve)
            .catch(reject);
    })
}

export function setColorWorkerData(data) {
    return new Promise((resolve, reject) => {
            return colorWorker.set(data)
            .then(resolve)
            .catch(reject);
    })
}
