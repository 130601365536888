import React, { Component, PropTypes } from 'react'

import { setPointExtractor } from '../../actions/polyshaper';



let propTypes = {
    name: PropTypes.string.isRequired,
    selected: React.PropTypes.oneOfType([
        React.PropTypes.bool,
        React.PropTypes.string
    ]).isRequired,
    task: PropTypes.func.isRequired,

    image: PropTypes.object.isRequired,
    imageData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    polyshaper: PropTypes.func.isRequired
};

export default class PointExtractor extends Component {

    componentWillReceiveProps(nextProps) {
        if (this.isActive() && nextProps.selected !== this.props.name) {
            this.onDeselected();
        }

        if (!this.isActive() && nextProps.selected === this.props.name) {
            this.onSelected();
        }
    }

    isActive() {
        return this.props.selected === this.props.name;
    }

    onDeselected() {} // to be overriden by inheriters
    onSelected() {} // to be overriden by inheriters

    deselect() {
        this.props.dispatch(setPointExtractor(false));
    }

    performTask(e) {
        this.props.task.call(this, e);
    }

    render() {
        return null;
    }
}

PointExtractor.propTypes = propTypes;
PointExtractor.defaultProps = {};