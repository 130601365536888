import React, { Component, PropTypes } from 'react';
import jsfeat from 'jsfeat';

import PointExtractor from './base';
import { addPoints } from '../../actions/polyshaper';


export default class BoundsExtractor extends PointExtractor {}

BoundsExtractor.defaultProps = {
    name: 'Bounds',
    task: function(e) {
        let polyshaper = this.props.polyshaper;
        let bitmap = polyshaper.bitmap();

        let width = this.props.image.width;
        let height = this.props.image.height;

        let points = [
            [0, 0],
            [width, 0],
            [0, height - 1],
            [width - 1, height - 1]
        ];

        this.props.dispatch(addPoints(points));
    }
}