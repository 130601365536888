/*eslint-disable */

import tinycolor from 'tinycolor2';

export default function toSVG(shapes, styles, bitmap, opts = {}) {
    let width = opts.width;
    let height = opts.height;
    let $svg = '';
    $svg += `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${width}" height="${height}" style="overflow: hidden;">`;

    if (bitmap.visible) {
        $svg += `<image xlink:href="${bitmap.image.src}"
                        x="${bitmap.x}px"
                        y="${bitmap.y}px"
                        height="${bitmap.image.width * bitmap.scaleX}px"
                        width="${bitmap.image.height * bitmap.scaleY}px"
                        opacity="${bitmap.alpha}" />`;
    }

    shapes.forEach((points, i) => {
        let paths = points.slice(1).map((p) => `L ${p.join(' ')}`).join('');
        let d = `M ${points[0].join(' ')}${paths}Z`;
        let style = styles[i];
        let css = Object.keys(style).map((key) => {
            return `${key}:${style[key] || 'none'};`;
        }).join(' ');

        $svg += `<path d="${d}" vector-effect="non-scaling-stroke" style="${css} stroke-linecap:butt; stroke-miterlimit:10;" />`;
        $svg += '\n';
    });

    $svg +=  `</svg>`;

    return $svg;
}

