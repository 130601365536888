import React, { Component, PropTypes } from 'react';

import { setPanCoords } from '../../actions/polyshaper';
import MouseTool from './base';


export default class PanMouseTool extends MouseTool {}

PanMouseTool.defaultProps = {
    name: 'pan',
    order: 5,
    keys: 'space',
    mouseEvent: 'stagemousedown',
    whilePressed: true,
    handledByDOM: true,
    task: function(e) {
        let polyshaper = this.props.polyshaper;
        let bitmap = polyshaper.bitmap();
        let stage = polyshaper.stage();
        let dispatch = this.props.dispatch;

        let offset = { x: stage.x - e.stageX, y: stage.y - e.stageY };

        stage.addEventListener('stagemousemove', function(ev) {
            let x = ev.stageX + offset.x;
            let y = ev.stageY + offset.y;
            dispatch(setPanCoords(x, y));  
        });

        stage.addEventListener('stagemouseup', function() {
            stage.removeAllEventListeners('stagemousemove');
        });
    }
}