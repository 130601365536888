import React, { Component, PropTypes } from 'react';
import shouldPureComponentUpdate from 'react-pure-render/function';
import { connect } from 'react-redux';
// import Droparea from 'react-droparea';

import Canvas from './components/Canvas';
import Modals from './components/Modals';
import Spinner from './components/Spinner';
import DropArea from './components/DropArea';

import { importImage, setImage, resetCanvas } from './actions/polyshaper';
import { identify } from './utils/mixpanel';

import './scss/App.scss';


class App extends Component {

    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        imageLoaded: PropTypes.bool.isRequired,
        app: PropTypes.object.isRequired
    };

    constructor() {
        super(...arguments);
        
        this._onDropFile = this._onDropFile.bind(this);
        this._importImageByUrl = this._importImageByUrl.bind(this);
    }

    shouldComponentUpdate = shouldPureComponentUpdate;

    componentDidMount() {
        // if (process.env.DEV && process.env.DEV_LOAD_INITIAL_IMAGE) {
        //     this._importImageByUrl(INITIAL_IMAGES[0]);
        // }

        identify();
    }

    renderDevTools() {
        if (process.env.DEV && process.env.DEV_REDUX_DEVTOOLS) {
            const DevTools = require('./containers/DevTools').default;
            return <DevTools />;
        }
    }

    _onDropFile(files) {
        const { dispatch } = this.props;

        dispatch(resetCanvas())
        dispatch(importImage(files))
    }

    _importImageByUrl(url) {
        url && this.props.dispatch(importImage(url));
    }

    render() {
        let { dispatch, app, imageLoaded} = this.props;

        let dropAreaClass = `Overlay Overlay--dropImage ${imageLoaded ? 'is-hidden' : ''}`;

        return (<div>
            <DropArea 
                onDrop={this._onDropFile}
                importImage={this._importImageByUrl}
                imageLoaded={imageLoaded}
            >
                {imageLoaded && <Canvas />}
            </DropArea>
            <Modals dispatch={dispatch}
                activeModal={app.modal}
            />
            <Spinner active={app.spinner} />
            {this.renderDevTools()}
        </div>);
    }
}

export default connect(function(state) {
    const presentControls = state.polyshaper.present; // using redux-undo

    return {
        imageLoaded: Boolean(presentControls.image && presentControls.image.src),
        // controls: state.polyshaper.present, // using redux-undo
        app: state.app
    };
})(App);
