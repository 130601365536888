import React, { Component, PropTypes } from 'react';
import jsfeat from 'jsfeat';

import PointExtractor from './base';
import { addPoints } from '../../actions/polyshaper';


export default class PhyllotaxisExtractor extends PointExtractor {
    constructor(props) {
        super(props);
        this.state = {
            spacing: 15,
            angleFactor: 1
        };
    }

    render() {
        if (!this.isActive()) {
            return null;
        } else {
            return (
                <div>
                <div className="Field">
                    <label>{`Spacing (${this.state.spacing})`}</label>
                    <input type="range"
                        min="3"
                        max="100"
                        step="1"
                        defaultValue={this.state.spacing}
                        onChange={(e) => {
                            this.setState({
                                spacing: e.target.value
                            })
                        }}
                    />
                </div>
                <div className="Field">
                    <label>{`Angle factor (${this.state.angleFactor})`}</label>
                    <input type="range"
                        min="1"
                        max="20"
                        step="1"
                        defaultValue={this.state.angleFactor}
                        onChange={(e) => {
                            this.setState({
                                angleFactor: e.target.value
                            })
                        }}
                    />
                </div>
                </div>
            );
        }

    }
}

PhyllotaxisExtractor.defaultProps = {
    name: 'Phyllotaxis',
    task: function(e) {
        let polyshaper = this.props.polyshaper;
        let bitmap = polyshaper.bitmap();

        let width = this.props.image.width;
        let height = this.props.image.height;
        let radius = Math.sqrt(width / 2 * width / 2 + height / 2 * height / 2) + 5;
        let θ = Math.PI * (3 - Math.sqrt(5)) * this.state.angleFactor;
        let spacing = this.state.spacing;
        let total = Math.ceil((radius * radius) / (spacing * spacing));

        let range = new Array(total).fill([]);
        let points = range.map(function(val, index) {
            let radius = spacing * Math.sqrt(index);
            let angle = index * θ;
            return [
                width / 2 + radius * Math.cos(angle),
                height / 2 + radius * Math.sin(angle)
            ];
        }).filter(function(p) {
            return p.length;
        });

        this.props.dispatch(addPoints(points));
    }
}