import React, { Component, PropTypes } from 'react'
import shouldPureComponentUpdate from 'react-pure-render/function';
import { setPointExtractor } from '../actions/polyshaper';

import PointExtractors from './point-extractors/';

export default class PointExtractorsControls extends Component {

    static propTypes = {
        selected: React.PropTypes.oneOfType([
            React.PropTypes.bool,
            React.PropTypes.string
        ]).isRequired,
        dispatch: PropTypes.func,
        polyshaper: PropTypes.func,
        image: PropTypes.object.isRequired,
        imageData: PropTypes.object.isRequired
    };

    shouldComponentUpdate = shouldPureComponentUpdate;

    setPointExtractor(extractor) {
        if (this.props.selected !== extractor) {
            this.props.dispatch(setPointExtractor(extractor));
        } else if (this.props.selected === extractor) {
            this.props.dispatch(setPointExtractor(false));
        }
    }

    performTask(e) {
        this.refs[this.props.selected].performTask(e);
    }

    render() {
        let {selected, polyshaper, dispatch, image, imageData} = this.props;
        let isSelected = (extractor) => extractor === selected ? {selected: true} : {};

        return (
            <menu className={'PointExtractors'}>
                <select defaultValue={selected}
                    onChange={e => this.setPointExtractor(
                        PointExtractors[e.target.selectedIndex].defaultProps.name)}
                >
                    {PointExtractors.map((PointEx) => {
                        let name = PointEx.defaultProps.name;
                        return (
                            <option
                                value={name}
                                key={name}
                            >
                                {name}
                            </option>);
                    })}
                </select>
                <button
                    className={'Button Button--native'}
                    onClick={e => this.performTask(e)}
                >
                    {'Add Points'}
                </button>
                {PointExtractors.map(PointEx => {
                    let name = PointEx.defaultProps.name;
                    return (
                        <PointEx
                            ref={name}
                            key={name}
                            selected={selected}
                            polyshaper={polyshaper}
                            dispatch={dispatch}
                            image={image}
                            imageData={imageData}
                        />)
                })}
            </menu>);
    }
}