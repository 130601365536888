import { createAction } from 'redux-actions';
import { batchActions } from 'redux-batched-actions';
import store from '../store';

export let addPoints = createAction('ADD_POINTS');
export let shapesGenerated = createAction('SHAPES_GENERATED');
export let stylesGenerated = createAction('STYLES_GENERATED');
export let movePoint = createAction('MOVE_POINT', function(pointIdx, point) {
    return { pointIdx, point };
});
export let removePoints = createAction('REMOVE_POINTS', function(pointsIdxs) {
    return pointsIdxs || [];
});
export let clearPoints = createAction('CLEAR_POINTS', function() {
    return [];
});
export let setPointExtractor = createAction('SET_POINT_EXTRACTION');

export let setShapeStroke = createAction('SET_SHAPE_STROKE');
export let setShapeStrokeOpacity = createAction(
    'SET_SHAPE_STROKE_OPACITY', (alpha) => {
        return parseFloat(parseFloat(alpha).toFixed(2));
});
export let setShapeStrokeColorMode = createAction('SET_SHAPE_STROKE_COLOR_MODE');
export let setShapeStrokeColor = createAction('SET_SHAPE_STROKE_COLOR');

export let setShapeFill = createAction('SET_SHAPE_FILL');
export let setShapeFillColorMode = createAction(
    'SET_SHAPE_FILL_COLOR_MODE');
export let setShapeFillColor = createAction('SET_SHAPE_FILL_COLOR');
export let setShapeFillOpacity = createAction('SET_SHAPE_FILL_OPACITY', (alpha) => {
    return parseFloat(parseFloat(alpha).toFixed(2));
});

export let setImage = createAction('SET_IMAGE');
export let importImage = createAction('IMPORT_IMAGE');
export let importImageFailed = createAction('IMPORT_IMAGE_FAILED');

export let toggleShapeFill = createAction('TOGGLE_SHAPE_FILL');
export let toggleShapeStroke = createAction('TOGGLE_SHAPE_STROKE');
export let toggleImage = createAction('TOGGLE_IMAGE_VISIBILITY');

export let resetCanvas = createAction('RESET_CANVAS', function() {
    store.dispatch(batchActions([
        clearPoints(),
        setZoom(1, [0, 0]),
        setPanCoords(0, 0)
    ]));
});

export let setImageVisibility = createAction('SET_IMAGE_VISIBILITY');
export let setImageOpacity = createAction('SET_IMAGE_OPACITY', (alpha) => {
    return parseFloat(parseFloat(alpha).toFixed(2));
});

export let setColorManipulation = createAction(
    'SET_COLOR_MANIPULATION', (transform, value) => {
        return {[transform]: parseFloat(value)};
    });

export let setColorManipulationInStroke = createAction(
    'SET_COLOR_MANIPULATION_IN_STROKE');
export let setColorManipulationInFill = createAction(
    'SET_COLOR_MANIPULATION_IN_FILL');

export let resetColorManipulation = createAction('RESET_COLOR_MANIPULATION');

export let setShapingAlgorithm = createAction('SET_SHAPING_ALGORITHM');


export let setMouseTool = createAction('SET_MOUSE_TOOL');
export let setZoom = createAction('SET_ZOOM', (
    zoom = 1,
    regCoords = [0, 0]
) => {
    return [zoom, regCoords];
});
export let increaseZoom = createAction('SET_ZOOM_INCREASE');
export let decreaseZoom = createAction('SET_ZOOM_DECREASE');
export let resetZoom = createAction('RESET_ZOOM');
export let setPanCoords = createAction('SET_PAN_COORDS', (x, y) => {
    return [x, y];
});

export let exportImage = createAction('EXPORT_IMAGE');
