import { handleActions } from 'redux-actions';
import { defaultProps as defaults } from '../components/Polyshaper';
import download from '../utils/download';


const reducer = handleActions({
    'SHAPES_GENERATED': (state, action) => {
        return {
            ...state,
            ...action.payload // points, shapes, styles
        }
    },
    'STYLES_GENERATED': (state, action) => {
        return {
            ...state,
            styles: action.payload
        }
    },
    'CLEAR_POINTS': (state, action) => {
        return {
            ...state,
            points: [],
            shapes: []
        }
    },
    'SET_POINT_EXTRACTION': (state, action) => {
        return {
            ...state,
            pointExtractor: action.payload
        }
    },
    'SET_SHAPE_STROKE': (state, action) => {
        return Object.assign({}, state, { shapeStroke: action.payload })
    },
    'SET_SHAPE_STROKE_OPACITY': (state, action) => {
        return Object.assign({}, state, { shapeStrokeOpacity: action.payload })
    },
    'SET_SHAPE_STROKE_COLOR_MODE': (state, action) => {
        return Object.assign({}, state, { shapeStrokeColorMode: action.payload })
    },
    'SET_SHAPE_STROKE_COLOR': (state, action) => {
        return Object.assign({}, state, { shapeStrokeColor: action.payload })
    },
    'SET_SHAPE_FILL': (state, action) => {
        return Object.assign({}, state, { shapeFill: action.payload })
    },
    'SET_SHAPE_FILL_COLOR_MODE': (state, action) => {
        return Object.assign({}, state, { shapeFillColorMode: action.payload })
    },
    'SET_SHAPE_FILL_COLOR': (state, action) => {
        return Object.assign({}, state, { shapeFillColor: action.payload })
    },
    'SET_SHAPE_FILL_OPACITY': (state, action) => {
        return Object.assign({}, state, { shapeFillOpacity: action.payload })
    },
    'SET_IMAGE': (state, action) => {
        return Object.assign({}, state, {
            image:  action.payload.image,
            imageData:  action.payload.imageData,
        })
    },
    'IMPORT_IMAGE_FAILED': (state, action) => {
        return Object.assign({}, state, {
            image: defaults.image,
            imageData:  defaults.imageData,
        })
    },
    'SET_IMAGE_VISIBILITY': (state, action) => {
        return Object.assign({}, state, { imageVisibility: action.payload })
    },
    'SET_IMAGE_OPACITY': (state, action) => {
        return Object.assign({}, state, { imageOpacity: action.payload })
    },
    'SET_MOUSE_TOOL': (state, action) => {
        return Object.assign({}, state, { tool: action.payload })
    },
    'SET_COLOR_MANIPULATION': (state, action) => {
        return Object.assign({}, state, {
            colorManipulation: {
                ...state.colorManipulation,
                ...action.payload
            }
        });
    },
    'SET_COLOR_MANIPULATION_IN_STROKE': (state, action) => {
        return Object.assign({}, state, { colorManipulationInStroke: !!action.payload });
    },
    'SET_COLOR_MANIPULATION_IN_FILL': (state, action) => {
        return Object.assign({}, state, { colorManipulationInFill: !!action.payload });
    },
    'RESET_COLOR_MANIPULATION': (state, action) => {
        return Object.assign({}, state, {
            colorManipulationInFill:  defaults.colorManipulationInFill,
            colorManipulationInStroke:  defaults.colorManipulationInStroke,
            colorManipulation: {
                ...defaults.colorManipulation
            }
        });
    },
    'SET_SHAPING_ALGORITHM': (state, action) => {
        return Object.assign({}, state, { shapingAlgorithm: action.payload });
    },
    'SET_ZOOM': (state, action) => {
        return Object.assign({}, state, { zoom: action.payload });
    },
    'RESET_ZOOM': (state, action) => {
        return Object.assign({}, state, {
            zoom: [1, [0, 0]],
            panCoords: [0, 0]
        });
    },
    'SET_ZOOM_INCREASE': (state, action) => {
        return Object.assign({}, state, {
            zoom: [state.zoom[0] * 1.15, null]
        });
    },
    'SET_ZOOM_DECREASE': (state, action) => {
        return Object.assign({}, state, {
            zoom: [state.zoom[0] / 1.15, null]
        });
    },
    'SET_PAN_COORDS': (state, action) => {
        return Object.assign({}, state, { panCoords: action.payload });
    },

    'TOGGLE_SHAPE_FILL': (state, action) => {
        return Object.assign({}, state, { shapeFill: !state.shapeFill })
    },
    'TOGGLE_SHAPE_STROKE': (state, action) => {
        return Object.assign({}, state, { shapeStroke: !state.shapeStroke })
    },
    'TOGGLE_IMAGE_VISIBILITY': (state, action) => {
        return Object.assign({}, state, { imageVisibility: !state.imageVisibility })
    },
    'EXPORT_IMAGE': (state, action) => {
        let image = action.payload;
        download(image.data, image.filename, image.mimetype);

        return state;
    }

}, defaults);


export default reducer;